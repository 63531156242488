<template>
  <div v-if="isShow" class="op-bg">
    <div class="modal">
      <img v-lazy-load class="contentImg" v-lazy-load :data-src="touchUrl" alt="" @click="touchJump">
      <img v-lazy-load class="closeBtn" v-lazy-load data-src="./image/icon_close.png" alt="" @click="closeHandle">
    </div>
  </div>
</template>

<script>
/**
 * 广告样式（一）
 */
import mixin from './mixin';
import trackReportMixin from '../Common/CommonMixin/trackReport'

export default {
  name: 'MarketComponents',
  mixins: [mixin, trackReportMixin],
}
</script>
<style lang="scss" scoped>
.op-bg {
  background: #22222260;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
}

.modal {
  width: px2vw(630);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .contentImg {
    width: 100%;
    margin-bottom: px2vw(32);
    cursor: pointer;
    border-radius: px2vw(32);
  }

  .closeBtn {
    cursor: pointer;
    width: px2vw(64);
    height: px2vw(64);
  }
}
</style>
