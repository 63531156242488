<template>
  <div class="map-wrapper">
    <div class="header">
      <p v-if="title" class="title">
        {{ title }}
      </p>
      <p v-if="description" class="intro">
        {{ description }}
      </p>
    </div>
    <div class="content">
      <div class="top">
        <iframe
          v-if="!userNetworkInfo.isMainland && !userNetworkInfo.isChina && userNetworkInfo.isMainland !== null"
          class="map-container"
          width="343"
          height="160"
          style="border: 0; border-radius: 8px 8px 0 0;"
          loading="lazy"
          v-lazy-load :data-src="mapUrl"
        />
        <img v-else v-lazy-load class="map-img" v-lazy-load :data-src="imgUrl">
      </div>
      <div class="bottom" v-html="leftContent" />
    </div>
  </div>
</template>
<script>
import mixin from './mixin';

export default {
  mixins: [mixin],
  data() {
    const { userNetworkInfo } = this.$store.state
    return {
      isChNetwork: false,
      userNetworkInfo,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.map-wrapper {
  padding: 0 16px;

  .header {
    text-align: center;

    .title {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      line-height: 30px;
    }

    .intro {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .content {
    margin-top: 22px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 345px;

    .bottom {
      padding: 16px;
      ::v-deep img {
        max-width: 100%;
      }
    }
    .top {
      display: flex;
      justify-content: center;
    }
    .map-img {
      width: 343px;
      height: 130px;
      border-radius: 8px 8px 0 0;
    }
  }
}
</style>
