<template>
  <div v-if="showSuction" class="SuctionBottom_outbox">
    <div v-if="SuctionBottomStatus" id="SuctionBottom_f" class="SuctionBottom_f">
      <div class="content">
        <!-- <div class="iconfont icon-close" @click="close()" /> -->
        <div class="description">
          {{ copywriting }}
        </div>
        <div class="button_content">
          <div v-for="(item, index) in dataList" :key="index" class="button btn-primary" @click="handleAccept(item)">
            {{ item.buttonCopywriting }}
          </div>
        </div>
      </div>
      <div class="close_btn_outbox">
        <img class="close_btn" v-lazy-load data-src="./image/icon_close.png" alt="" @click="closeShowSuction">
      </div>
    </div>

    <div id="SuctionBottom_r" class="SuctionBottom_r">
      <div class="content">
        <div class="description">
          {{ copywriting }}
        </div>
        <div class="button_content">
          <div v-for="(item, index) in dataList" :key="index" class="button btn-primary" @click="handleAccept(item)">
            {{ item.buttonCopywriting }}
          </div>
        </div>
      </div>
      <div class="close_btn_outbox">
        <img class="close_btn" v-lazy-load data-src="./image/icon_close.png" alt="" @click="showSuction=false">
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin';
// import bus from 'common/bus'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import { getShowSuctionState, setShowSuctionState } from 'common/utils.js';

export default {
  mixins: [mixin, trackReportMixin],
  data() {
    return {
      SuctionBottomStatus: true,
      showSuction: false,
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.ucenter.isLogin;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.showSuction = getShowSuctionState(this.$route.path)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    closeShowSuction() {
      setShowSuctionState(this.$route.path)
      this.showSuction = false
    },
    handleScroll() {
      const suctionBottom = document.getElementById('SuctionBottom_r');
      const height = suctionBottom.offsetHeight;

      // const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const element = document.querySelector('.SuctionBottom_r');
      const rect = element.getBoundingClientRect();
      const offset = rect.top - document.documentElement.clientHeight;
      if ((offset + height) > 0) {
        this.SuctionBottomStatus = true
      } else {
        this.SuctionBottomStatus = false
      }
    },
    handleAccept(item) {
      console.log(item)
      if (item.skip === '_blank') { // 功能性跳转
        // 埋点上报
        this.buttonTrackReport({
          buttonName: 'toShowDiagnosis',
        });
        if (!this.isLogin) {
          // bus.$emit('login', {triggerSource: 'diagnosis'});
          const currentFullPath = this.$route.fullPath
          const backUrl = encodeURI(currentFullPath)
          this.$router.replace(`/login?cancelUrl=${backUrl}&successUrl=${backUrl}&triggerSource=appointment`)
          return
        }
        // bus.$emit('toShowDiagnosis', { type: 1})
        const currentFullPath = this.$route.fullPath
        const backUrl = encodeURI(currentFullPath)
        this.$router.replace(`/appointmentDiagnosis?successUrl=${backUrl}`);
      }
      if (item.skip === '_self') { // 自定义链接跳转
        if (item.skipWay === '_blank') { // 在当前页面打开
          window.location.href = item.skipLink;
        }
        if (item.skipWay === '_self') { // 在新标签页打开
          window.open(item.skipLink, '_blank');
        }
      }
    },
    close() {
      this.SuctionBottomStatus = false
    },
  },
}
</script>

<style lang="scss" scoped>
  .SuctionBottom_outbox {
    position: relative;
  }

  .close_btn_outbox {
    position: absolute;
    top: 10px;
    right: 26px;
  }

  .close_btn {
    width: 20px;
    height: 20px;
  }

  .SuctionBottom_f {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
  }

  .SuctionBottom_r {
    position: relative;
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
  }

  .content {
    width: 100%;
    padding-top: 34px;
    padding-bottom: 16px;
    background: var(--txt-color-link);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0 auto;
  }

  .description {
    width: 311px;

    /* height: 40px; */
    font-size: 14px;
    font-family: 'PingFangSC-Semibold', 'PingFangSC', sans-serif;
    font-weight: 600;
    color: #fff;
    line-height: 20px;

    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis; */
    margin: 0 auto;

    /* padding-right: 20px; */
  }

  .button_content {
    display: flex;
    flex-direction: column;
  }

  .button {
    width: 311px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 16px;
    background: #fff;
    font-size: 16px;
    font-family: 'PingFangSC-Semibold', 'PingFang SC', sans-serif;
    font-weight: 600;
    color: var(--txt-color-link);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .iconfont {
    position: absolute;
    font-size: 28px;
    right: 16px;
    top: 4px;
    margin-right: 4px;
    color: #fff;
  }
</style>
