import { render, staticRenderFns } from "./touch.vue?vue&type=template&id=3b7b5a50&scoped=true&"
import script from "./touch.vue?vue&type=script&lang=js&"
export * from "./touch.vue?vue&type=script&lang=js&"
import style0 from "./touch.vue?vue&type=style&index=0&id=3b7b5a50&prod&lang=css&"
import style1 from "./touch.vue?vue&type=style&index=1&id=3b7b5a50&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7b5a50",
  null
  
)

export default component.exports