<template>
  <div>
    <div @click="registrationHandler({way: 'NORMAL'})">
      <slot />
    </div>
    <SwitchStudentPopup
      ref="registrationSwitchStudentPopup"
      :switch-stu-tip-text="switchStuTipText"
      @switch-success="switchSuccessHandler"
    />
    <ConfirmDialog :show.sync="confirmDialogConfig.show" :title="confirmDialogConfig.title" :content="confirmDialogConfig.content"
                   :main-btn-text="confirmDialogConfig.mainBtnText" :sub-btn-text="confirmDialogConfig.subBtnText"
                   @mainBtnClick="confirmDialogConfig.mainBtnClick" @subBtnClick="confirmDialogConfig.subBtnClick"
    >
      <template v-if="signUpStatus == 1 || signUpStatus == 2" v-slot:content-bottom>
        <div class="evaluation-redeem-code-entrance">
          <p class="desc-text font-medium" @click="openEvaluationRedeemDialog">
            {{ $t('coursesDetail.redeemCodeEntrance[0]') }} <span class="btn-text hover-transition">{{ $t('coursesDetail.redeemCodeEntrance[1]') }}</span>
          </p>
        </div>
      </template>
    </ConfirmDialog>
    <RedeemDialog ref="evaluationRedeemDialog" origin="registration" :course-detail="detailData" @main-click="redeemMainClickHandler" />
  </div>
</template>
<script>
import bus from 'common/bus';
import { oneApi } from 'config/api';
import evaluation from 'common/evaluation.js';
import SwitchStudentPopup from '@/components/touch/courses/SwitchStudentPopup/index.vue';
import ConfirmDialog from 'components/common/ConfirmDialog/index.vue';
import RedeemDialog from 'components/test/RedeemDialog';

// mixins
import coursesSa from 'mixins/courses/sa.js';
import coursesCommon from 'mixins/courses/common.js';
import couponMixins from 'mixins/coupon/index.js';
import activity from 'mixins/courses/detail/activity.js';

import filters from 'common/filters.js';

export default {
  components: { SwitchStudentPopup, ConfirmDialog, RedeemDialog },
  mixins: [
    coursesCommon,
    couponMixins,
    activity,
    coursesSa,
  ],
  props: {
    orderInfo: {
      type: Object,
      default: () => {},
    },
    btnDisabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    const courseId = this.orderInfo.id;
    const { fromPage } = this.$route.query;
    return {
      studentsList: [],
      studentInfo: null,
      fromSource: '',
      isOnSale: true,
      // 是否正在初始化
      isInitLoading: false,
      // 购买流程中
      isBuying: false,
      // 班级详情数据请求状态
      // 0: 请求中
      // 1: 请求成功
      // 2: 请求失败
      queryDetailStatus: 0,
      // 切换学员后是否需要刷新数据
      isNeedRefresh: false,
      courseId,
      skuId: courseId,
      // 详情页数据
      detailData: {},
      // 课程大纲数据
      courseSchedule: [],
      // 下载工具
      tool: '',
      // 年级
      grade: '',
      // 初始化loading的高度
      marginTop: 300,
      // 诊断弹窗信息
      evaluationDialogInfo: {
        title: '',
        message: '',
        confirmButtonText: '',
        showCancel: true,
        res: {},
      },
      // 诊断入口
      evaluationName: this.$t('evaluation.name'),
      evaluation: {
        entranceStatus: 0,
        examId: '',
      },
      // 报班资格校验结果
      signUpStatus: 0,
      showEvaluationDialog: false,
      // 售卖倒计时
      signUpRemainTime: 0,
      headerRef: null,
      isWish: false,
      isFirstWish: false,
      isWishEnd: true,
      isError: false,
      activeIndex: 0,
      fromPage,
      addWishRes: null,
      res: null,
      confirmDialogConfig: {
        show: false,
        title: '',
        content: '',
        mainBtnText: '',
        mainBtnClick: () => {},
        subBtnText: '',
        subBtnClick: () => {},
      },
      // 价格状态相关参数
      showCouponBubble: true,
      courseInformationTop: 0,
      // 1 长期班，其余 非长期班
      courseType: 1,
    };
  },
  computed: {
    switchStuTipText() {
      if (this.recommendDiscountPrice > 0) {
        return ''
      }
      if (this.activityInfo.activityType === 6) {
        if (!this.groupData || !this.groupData.groupParam) return ''
        const groupParam = this.groupData.groupParam || {}
        if ((Array.isArray(groupParam.creatorTags) && groupParam.creatorTags.length > 0)
            || (Array.isArray(groupParam.memberTags) && groupParam.memberTags.length > 0)) {
          return this.$t('order.shoppingCart.promoNotice')
        }
        return ''
      }
      return ''
    },
    locale() {
      return this.$store.state.locale;
    },
    isBetterMe() {
      return this.$store.state.betterme;
    },
    easternNameOrderEnabled() {
      const { easternNameOrderEnabled } = JSON.parse(window.localStorage.getItem('clientOptions')) || {};
      return easternNameOrderEnabled || false;
    },
    // 获取课程类型列表
    coursesTypeList() {
      return this.$store.state.courses.coursesTypeList;
    },
    pageTitle() {
      return this.orderInfo.title;
    },
    // 是否显示诊断提示
    showAssessmentNotice() {
      // 新加坡 并且 年级为: 1、2、3、4、5时显示
      return (
        this.$store.state.locale === 'sg'
        && this.grade !== ''
        && this.grade >= 0
        && this.grade <= 4
      );
    },
    // 诊断测试地址
    assessmentTestUrl() {
      const gradeAssessmentMap = this.$t('courses.gradeAssessmentMap');
      const url = gradeAssessmentMap[this.grade];
      return url;
    },
    // 是否满班状态
    btnStatus() {
      const { signUpRemainTime, detailData } = this;
      const {
        spec: { courseClosed },
        sellStore,
        store,
        subscriptionsStatus,
      } = detailData;
      if (courseClosed) {
        return this.formPage === 'unpaid-courses-list'
          ? 'unavailable'
          : 'courseEnded';
      }
      if (signUpRemainTime > 1000) {
        return 'forSale';
      }
      // 自动扣款中
      if (subscriptionsStatus === 1) {
        return 'enrolNow'; // autoPayInProcess
      }
      // 待缴费清单不存在报满状态
      if (store - sellStore <= 0 && this.fromPage !== 'unpaid-courses-list') {
        return 'full';
      }
      if (this.fromPage === 'unpaid-courses-list') {
        return 'enrolNow';
      }
      return 'enrol';
    },
    allTeacherList() {
      const allList = this.orderInfo.spec.teacherList.concat(
        this.orderInfo.spec.tutorList,
      );
      return allList;
    },

    priceModel() {
      if (
        Number(this.orderInfo.showOrgPrice) === 0
        && Number(this.orderInfo.showPrice) === 0
      ) {
        return 'free';
      }
      if (
        Number(this.orderInfo.showOrgPrice)
        <= Number(this.orderInfo.showPrice)
      ) {
        return 'noDiscount';
      }
      return 'discount';
    },
    currency() {
      return this.$store.state.currency.symbol;
    },
    minorUnit() {
      return this.$store.state.currency.minorUnit;
    },
    operation() {
      return JSON.parse(this.orderInfo.operation || '{}');
    },
    highlights() {
      return this.operation.dynamic && this.operation.dynamic.highlight;
    },
    evaluationStatusTxt() {
      let result = '';
      switch (this.evaluation.entranceStatus) {
        case 0:
          break;
        case 1:
          result = this.$t('coursesDetail.evaluationStatusTxt.case1');
          break;
        case 2:
          result = this.$t('coursesDetail.evaluationStatusTxt.case2');
          break;
        case 3:
          result = this.$t('coursesDetail.evaluationStatusTxt.case3');
          break;
        case 4:
          result = this.$t('coursesDetail.evaluationStatusTxt.case4');
          break;
        default:
          break;
      }
      return result;
    },
    discountText() {
      return `${this.currency}${filters.priceUnitConvert(
        this.recommendDiscountPrice,
        this.minorUnit,
        this.$t('common.isKeepTwoDecimal'),
      )}`;
    },
  },
  methods: {
    // 兑换成功后按钮点击事件处理
    redeemMainClickHandler(redeemResult) {
      if (!redeemResult.isRedeem) {
        // 继续报名
        this.registrationCourse()
      } else {
        const code = redeemResult.redeemCode || ''
        this.$router.push(`/test/recommended-classes/${code}?studentId=${this.studentId}`)
      }
    },
    // 神策埋点-诊断兑换码弹窗曝光
    evaluationRedeemDialogShow() {
      this.Sensors.track('qualification_code_pv', {
        course_id: this.detailData.spec.courseId,
        class_id: this.detailData.spec.clazzId,
        previous_source: '班级详情页',
      })
    },
    // 诊断兑换码入口点击事件
    openEvaluationRedeemDialog() {
      // 关闭当前弹窗
      this.setConfirmDialog({ show: false })
      this.$refs.evaluationRedeemDialog.openDialog()
      this.evaluationRedeemDialogShow()
    },
    // 切换学员成功处理
    switchSuccessHandler(isSwitch, studentInfo) {
      this.isNeedRefresh = isSwitch
      this.studentInfo = studentInfo
      this.registrationCourse()
    },
    async init() {
      this.isInitLoading = true;
      await this.queryCourseDetail();
      this.isInitLoading = false;
    },
    /**
     * 报名
     */
    async registrationHandler() {
      if (this.btnDisabled) {
        console.log('不可购买');
        return
      }
      const { orderInfo } = this;
      console.log('进了这里');
      this.isBuying = true;
      const skuId = orderInfo.id;
      const orderCreateData = { ...orderInfo };
      orderCreateData.skuId = skuId;
      if (orderInfo.way === 'ACTIVITY_GROUP_NEW') {
        orderCreateData.activityId = this.activityInfo.activityId;
        orderCreateData.activityType = this.activityInfo.activityType;
      }
      // 更新下单途径
      this.$store.dispatch('order/updateCreateOrderInfo', orderCreateData);
      // 更新参与商城活动类型
      this.$store.dispatch('attribution/updateEvent', {
        orderCreateWay: this.$store.state.order.orderCreateWay,
        activityType:
          this.activityInfo.activityType
          || this.$store.state.attribution.eventType,
        activityId:
          this.activityInfo.activityId || this.$store.state.attribution.eventId,
      });

      await this.registrationCheckUser(
        orderInfo.way === 'ACTIVITY_GROUP_NEW'
          || orderInfo.way === 'ACTIVITY_GROUP_JOIN',
      );

      this.courseEnrollClick();
    },

    async registrationCheckUser(isGroup = false) {
      this.isBuying = true;
      // 判断是否登录状态
      const { isLogin } = this.$store.state.ucenter;
      if (!isLogin) {
        bus.$emit('login', {
          triggerSrc: 'REGISTRATION_COURSE',
          triggerSource: 'courses-detail-registration',
          isGroup,
        });
        this.isBuying = false;
        return;
      }

      // 子账号优化：
      // 家庭中无子账号+首次下单 => 去掉选择子账号流程
      // 判断是否有子账号
      const resPromise = this.$axios.post(
        oneApi.getFamilyStudentsList,
        {},
        { rewritePostBody: true },
      );
      const infoPromise = this.$axios.post(
        oneApi.queryUserBasicInfo,
        { fillInAttention: true },
        { rewritePostBody: true, uid: this.studentId },
      );
      const res = await resPromise;
      const info = await infoPromise;
      if (Number(res.code) !== 0) {
        this.isLoading = false;
        this.$Toast(res.msg);
        return;
      }
      const resData = res.data || {};
      this.studentsList = [
        resData.currentAccount,
        ...(resData.associatedAccount || []),
      ];
      const hasAccount = resData.associatedAccount && resData.associatedAccount.length > 0;
      // 1、若家庭组中无子账号
      // 若为第一次下单，则去掉选择子账号流程
      // tagId === 1，首次下单
      // 若账号下已经有订单（下过单即可，取消的也算），则正常弹窗引导用户选择子账号
      // 2、若家庭组中有子账号
      // 同当前线上，弹窗引导用户选择子账号
      console.log('有子账号 - ', hasAccount);
      console.log('是否是首次下单', info);
      console.log(this.fromPage);
      this.studentInfo = {
        uid: info.data.id,
        nickName: info.data.nickName || '',
        firstName: info.data.firstName || '',
        lastName: info.data.lastName || '',
        email: info.data.email || '',
        phone: info.data.phone || '',
      };
      if (this.studentId) {
        this.registrationCourse();
      } else if (!hasAccount && [1, '1'].includes(info.data.tagId)) {
        console.log('绕过学生弹窗，直接走报名校验');
        this.registrationCourse();
      } else {
        console.log('选择学生弹窗');
        // 选择学员
        this.openSwitchStudentDialog();
      }
    },

    openSwitchStudentDialog() {
      this.$refs.registrationSwitchStudentPopup.openPicker();
    },

    /** 报名校验 */
    async registrationCourse() {
      console.log('开始校验');
      this.isBuying = true;
      if (!this.$store.state.ucenter.isLogin) {
        bus.$emit('login', { triggerSrc: 'REGISTRATION_COURSE' });
        return;
      }
      if (this.fromPage !== 'unpaid-courses-list') {
        // 需要校验用户诊断成绩
        const stuEvaluationStatus = await this.checkStuEvaluation();
        // 诊断校验未通过，无法报名
        if (!stuEvaluationStatus) {
          this.isBuying = false;
          return;
        }
      } else {
        const orderSkuId = this.orderInfo.id;
        const data = { skuId: orderSkuId };
        const res = await this.$axios.post(oneApi.toBePaidCheck, data, {
          rewritePostBody: true,
          uid: this.studentId,
        });
        if (Number(res.code) !== 0) {
          this.$Toast(res.msg);
          return;
        }
        const resData = res.data.helper || {};
        if (resData.status === 1) {
          this.checkEmailOrPhone('email', true, this.newEmailOrPhone);
          return;
        }
        if (resData.status === 2) {
          this.checkEmailOrPhone('phone', true, this.newEmailOrPhone);
          return;
        }
        // 需要完善个人信息
        if (resData.status === 3) {
          bus.$emit('complete-stu-info', {
            triggerSrc: 'REGISTRATION_COURSE',
            studentInfo: this.studentInfo || this.studentsList[0],
          });
          this.isBuying = false;
          return;
        }
      }
      // 去生成订单
      this.createOrder();
    },
    async createOrder() {
      if (!await this.activityRegistrationCheck()) { this.isBuying = false; return }
      const path = this.$store.state.order.createOrderPath
      const query = Object.assign(this.$route.query, this.$store.state.order.createOrderQuery)
      if (!path) { this.isBuying = false; return }
      this.$router.push({
        path,
        query,
      });
      console.log(path, query, 'path,query');
      this.isBuying = false;
    },
    async newEmailOrPhone(obj) {
      let data = {};
      let res;
      if (obj.phone) {
        data = {
          attentionPhone: obj.phone,
          attentionCountryCode: obj.phoneAreaCode.value,
        };
        res = await this.$axios.post(oneApi.bindEmail2, data, {
          rewritePostBody: true,
          uid: this.studentId,
        });
      } else {
        data = {
          attentionEmail: obj,
        };
        res = await this.$axios.post(oneApi.bindEmail2, data, {
          rewritePostBody: true,
          uid: this.studentId,
        });
      }

      // 触发后续购课流程
      if (Number(res.code) !== 0) {
        this.$Toast(res.msg);
        return;
      }
      this.$store.dispatch('ucenter/queryUserBasicInfo', {
        $axios: this.$axios,
        userData: { fillInAttention: false },
      });
      if (this.$route.name === 'my-unpaid-courses-list') {
        bus.$emit('unpaid.list.registrationCourses', { uid: this.studentId });
      }
      this.$store.dispatch('bindDialog/close');
      this.registrationCourse();
    },
    checkEmailOrPhone(type, execute, guide) {
      // 绑定手机号/邮箱优化
      this.$store.dispatch('bindDialog/setDialogData', {
        visible: true,
        type,
        execute: execute || false,
        guide: guide || null,
      });
    },
    // 根据用户诊断成绩校验用户报名资格
    async checkStuEvaluation() {
      const orderSkuId = this.orderInfo.id;
      const data = { skuId: orderSkuId };
      const res = await this.$axios.post(oneApi.querySignUpCheckv2, data, {
        rewritePostBody: true,
        uid: this.studentId,
      });
      if (res.code !== 0) {
        this.$Toast(res.msg);
        return false;
      }

      const resData = res.data || {};
      if (resData.continue) return true;

      // 判断是新诊断逻辑还是旧诊断逻辑
      if (resData.helper && resData.helper.old) {
        this.setEvaluationDialogOld(resData);
      } else {
        const helper = resData.helper || {};
        this.setSignUpCheckInfoDialog(helper);
      }

      // continue 为false，需要根据helper做进一步处理

      return false;
    },
    // 报名资格校验结果
    setSignUpCheckInfoDialog(helper) {
      const status = helper.status || helper.status === 0 ? helper.status : '';
      this.signUpStatus = status;
      switch (status) {
        case 1: {
          // 需要入学测，剩余作答机会>0
          const leftChance = helper.extra && helper.extra.leftChance
            ? helper.extra.leftChance
            : 0;
          const examId = helper.extra && helper.extra.examId ? helper.extra.examId : '';
          const queryObj = {
            courseId: this.orderInfo.spec.courseId || '',
            skuId: this.orderInfo.id,
          };
          const isTmc = this.$store.state.locale === 'tmc';
          this.setConfirmDialog({
            show: true,
            title: this.$t('coursesDetail.signUpCheckInfoDialog.case1.title'),
            content: this.$t(
              'coursesDetail.signUpCheckInfoDialog.case1.content',
              [
                leftChance,
                leftChance > 1 ? 's' : '',
                isTmc
                  ? `您的孩子需要通过入学诊断，然后才能报名参加这个课程，只有${leftChance}次机会`
                  : '',
              ],
            ),
            mainBtnText: this.$t(
              'coursesDetail.signUpCheckInfoDialog.case1.mainBtnText',
            ),
            mainBtnClick: () => {
              // 美分&非数学学科
              const subjuetFullName = this.orderInfo.spec.subjectFullName.toLocaleLowerCase() || '';
              if (
                this.$store.state.locale === 'us'
                && !subjuetFullName.includes('math')
              ) {
                this.$router.push('/1-to-1-assessment');
                return;
              }
              if (!examId) {
                this.$Toast('no examId');
                if (this.isNeedRefresh) this.init();
                return;
              }
              evaluation.toEvaluation(
                this.$store,
                `/evaluation/instruction/${examId}`,
                queryObj,
                'class_register_pop_up',
              );
              this.startTestClick(examId);
              if (this.isNeedRefresh) this.init();
            },
            subBtnText: this.$t(
              'coursesDetail.signUpCheckInfoDialog.case604.subBtnText',
            ),
            subBtnClick: () => {
              this.confirmDialogConfig.show = false;
              if (this.isNeedRefresh) this.init();
            },
          });
          break;
        }
        case 2: {
          // 无法报名-诊断校验未通过
          this.setConfirmDialog({
            show: true,
            title: this.$t(
              'coursesDetail.signUpCheckInfoDialog.case2.unableSignUp',
            ),
            content:
              helper.statusDesc
              || this.$t('coursesDetail.signUpCheckInfoDialog.case2.msg'),
            mainBtnText: this.$t(
              'coursesDetail.signUpCheckInfoDialog.case2.ok',
            ),
            mainBtnClick: () => {
              // 美分&非数学学科
              const subjuetFullName = this.orderInfo.spec.subjectFullName.toLocaleLowerCase() || '';
              if (
                this.$store.state.locale === 'us'
                && !subjuetFullName.includes('math')
              ) {
                this.$router.push('/1-to-1-assessment');
                return;
              }
              this.confirmDialogConfig.show = false;
              if (this.isNeedRefresh) this.init();
            },
          });
          break;
        }
        case 3: {
          // 无法报名-不符合报班资格(不符合班课策略要求)
          const msg = helper.statusDesc || '';
          this.setConfirmDialog({
            show: true,
            title: this.$t('coursesDetail.signUpCheckInfoDialog.case3.title'),
            content: msg,
            mainBtnText: this.$t(
              'coursesDetail.signUpCheckInfoDialog.case3.mainBtnText',
            ),
            mainBtnClick: () => {
              this.confirmDialogConfig.show = false;
              if (this.isNeedRefresh) this.init();
            },
            subBtnText: '',
            subBtnClick: () => {},
          });
          break;
        }
        case 4: {
          this.checkEmailOrPhone('email', true, this.newEmailOrPhone);
          break;
        }
        case 5: {
          this.checkEmailOrPhone('phone', true, this.newEmailOrPhone);
          break;
        }
        case 6: {
          // 需要完善个人信息
          bus.$emit('complete-stu-info', {
            triggerSrc: 'REGISTRATION_COURSE',
            studentInfo: this.studentInfo || this.studentsList[0],
          });
          this.isBuying = false;
          break;
        }
        case 1014: {
          // 存在自动扣款中的分期
          this.setConfirmDialog({
            show: true,
            title: this.$t(
              'coursesDetail.signUpCheckInfoDialog.case1014.title',
            ),
            content: this.$t(
              'coursesDetail.signUpCheckInfoDialog.case1014.content',
            ),
            mainBtnText: this.$t(
              'coursesDetail.signUpCheckInfoDialog.case1014.mainBtnText',
            ),
            mainBtnClick: () => {
              this.confirmDialogConfig.show = false;
              this.init();
            },
            subBtnText: '',
            subBtnClick: () => {},
          });
          break;
        }
        case 604: {
          // 限时购-简版提示弹窗
          this.setConfirmDialog({
            show: true,
            title: this.$t('coursesDetail.signUpCheckInfoDialog.case604.title'),
            content: this.$t(
              'coursesDetail.signUpCheckInfoDialog.case604.content',
            ),
            mainBtnText: this.$t(
              'coursesDetail.signUpCheckInfoDialog.case604.mainBtnText',
            ),
            mainBtnClick: () => {
              this.confirmDialogConfig.show = false;
              this.$router.push('/courses/list');
            },
            subBtnText: this.$t(
              'coursesDetail.signUpCheckInfoDialog.case604.subBtnText',
            ),
            subBtnClick: () => {
              this.confirmDialogConfig.show = false;
            },
          });
          break;
        }
        default: {
          const msg = helper.statusDesc || 'system error';
          this.$Toast(msg);
          if (this.isNeedRefresh) this.init();
          break;
        }
      }
    },
    // 设置确认弹窗
    setConfirmDialog(...args) {
      this.confirmDialogConfig = Object.assign(
        this.confirmDialogConfig,
        args[0],
      );
    },
    // 旧诊断处理逻辑
    setEvaluationDialogOld(res) {
      //  需参与诊断测试
      if (Number(res.helper.status) === 1) {
        this.evaluationDialogInfo = {
          title: 'Evaluation Required',
          message:
            "Think Academy will recommend the best fit class based on the student's evaluation test",
          confirmButtonText: 'Take Evaluation Now',
          showCancel: true,
          res,
        };
        this.showEvaluationDialog = true;
        return;
      }
      // 有成绩但是不符合报班条件
      if (Number(res.helper.status) === 2) {
        this.evaluationDialogInfo = {
          title: "Your evaluation score doesn't satisfy this class level",
          message:
            'Based on your evaluation, we recommend you for 1 class level',
          confirmButtonText: 'OK',
          showCancel: false,
          res,
        };
        this.showEvaluationDialog = true;
        return;
      }
      // 需要不符合班课策略要求 - 已报名
      if (Number(res.helper.status) === 3) {
        this.evaluationDialogInfo = {
          title: 'Reminder',
          message: res.helper.statusDesc,
          confirmButtonText: 'Confirm',
          showCancel: false,
          res,
        };
        this.showEvaluationDialog = true;
      }
      //  账号缺少联系邮箱
      if (Number(res.helper.status) === 4) {
        this.checkEmailOrPhone('email', true, this.newEmailOrPhone);
        return;
      }
      //  账号缺少联系手机号
      if (Number(res.helper.status) === 5) {
        this.checkEmailOrPhone('phone', true, this.newEmailOrPhone);
      }
      //  需要完善个人信息
      if (Number(res.helper.status) === 6) {
        bus.$emit('complete-stu-info', {
          triggerSrc: 'REGISTRATION_COURSE',
          studentInfo: this.studentInfo || this.studentsList[0],
        });
        this.isBuying = false;
      }
    },

    // 请求课程详情页面
    async queryCourseDetail() {
      this.queryDetailStatus = 0
      const data = {
        skuId: this.orderInfo.id,
      }
      const res = await this.$axios.post(oneApi.queryCourseDetail, data, { rewritePostBody: true });
      if (Number(res.code) !== 0) {
        this.isError = true;
        this.queryDetailStatus = 2
        if (Number(res.code) === 1) { this.isOnSale = false; return }
        this.$Toast(res.msg);
        return
      }
      const resData = res.data || {}
      this.detailData = resData || {};
      this.courseType = resData.spec.courseType
      // 设置活动数据
      this.activityInfo = Object.assign(this.activityInfo, resData.activityInfo)
      let { remainSellTime } = resData
      // 香港分校倒计时增加5秒随机，防止大量用户同时购买
      if (this.locale === 'hk' && remainSellTime > 1000) {
        const randomMillisecond = window.localStorage.getItem('_official_random_millisecond')
        if (randomMillisecond) {
          remainSellTime += Number(randomMillisecond)
        }
      }
      this.signUpRemainTime = remainSellTime
      this.courseSchedule = JSON.parse(JSON.stringify(this.orderInfo.spec.courseInfo.syllabus).replace(/name/g, 'lecture_name').replace(/timeDesc/g, 'lecture_time'))
      const examEntrance = resData.examEntrance || {}
      this.evaluation.entranceStatus = examEntrance.status || ''
      this.evaluation.examId = examEntrance.examId || ''
      this.queryDetailStatus = 1

      if (this.$store.state.ucenter.isLogin && this.btnStatus.includes('enrol')) {
        console.log('this.detailData.existStage', this.orderInfo.existStage)
        if (this.orderInfo.existStage) await this.getGenerate(this.orderInfo)
        this.queryCoupons(this.orderInfo)
        this.getConStudentList(this.orderInfo)
      }
      this.init()
      this.courseDetailPv()
      this.getFollowRes(this.skuId)
    },

    // 神策埋点 - 班级详情页PV
    courseDetailPv() {
      const fromSourcePage = this.getClassFrom(this.fromUrl)
      const data = this.getClassSaData(fromSourcePage, this.orderInfo)
      data.active_type = this.activityInfo.activityType || null
      data.active_id = this.activityInfo.activityId || null
      this.Sensors.track('class_detail_pv', data)
    },
    // 神策埋点-点击报名
    courseEnrollClick() {
      const fromSourcePage = this.getClassFrom(this.fromSource);
      const data = this.getClassSaData(fromSourcePage, this.orderInfo);
      data.active_type = this.activityInfo.activityType || null;
      this.Sensors.track('enroll_click', data);
    },
    // 神策埋点-开始诊断
    startTestClick(examId) {
      this.Sensors.track('enroll_start_test_click', {
        course_id: this.orderInfo.spec.courseId,
        class_id: this.orderInfo.spec.clazzId,
        quiz_id: examId,
      })
    },
  },
};
</script>
<style lang="scss" scoped>
// 诊断兑换码入口
.evaluation-redeem-code-entrance {
  margin: px2vw(32) px2vw(48) 0;
  padding: px2vw(16);
  font-size: px2vw(24);
  line-height: px2vw(28);
  text-align: center;
  border-radius: px2vw(20);
  color: var(--txt-color-lv2);
  background: rgba(244, 246, 250, 0.5);

  .btn-text {
    color: var(--txt-color-link);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: var(--txt-color-link2);
    }
  }
}
</style>
