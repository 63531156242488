<template>
  <div class="component-wrap">
    <div v-for="item in renderComponentList" :key="item.key" class="component-wrapper">
      <component
        :is="item.componentName"
        v-sa-track="[{type: 'visually', event: 'component_show', data: getTrackData(item)}]"
        :locale="locale"
        :module-id="item.moduleId"
        :component-name="item.componentName"
        :component-type="item.componentType"
        :component-title="item.componentTitle"
        :registration="item.registration"
        v-bind="item.componentData"
      />
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import Banner from './Banner/touch'
import Banner2 from './Banner-2/touch'
import Banner3 from './Banner-3/touch'
import Category from './Category/touch'
import MockExamColumn from './MockExamColumn/touch'
import ModuleRichText from './ModuleRichText/touch'
import PageRichText from './PageRichText/touch'
import GetNotified from './GetNotified/touch'
import GetNotified2 from './GetNotified-2/touch'
import Application from './Application/touch'
import Trustpilot from './Trustpilot/touch'
import CarouselComponent from './CarouselComponent/touch'
import SuctionBottom from './SuctionBottom/touch'
import CourseListComponent from './CourseListComponent/touch'
import NewsGroup from './NewsGroup/touch'
import UserComments from './UserComments/touch'
import Ad1 from './Ad-1/touch'
import Ad2 from './Ad-2/touch'
import Ad3 from './Ad-3/touch'
import Ad4 from './Ad-4/touch'
import Ad5 from './Ad-5/touch'
import Ad6 from './Ad-6/touch'
import Ad7 from './Ad-7/touch'
import Ad8 from './Ad-8/touch'
import Ad9 from './Ad-9/touch'
import CustomerForm from './CustomerForm/touch'
import CustomButton from './CustomButton/touch'
import ImageList from './ImageList/touch'
import generalImgTwo from './generalImgTwo/touch'
import UserCommentsBigPicture from './UserCommentsBigPicture/touch'
import Faqs from './Faqs/touch'
import RichTextColumn from './RichTextColumn/touch'
import resourcesColumn from './Resource/touch'
import ClassColumn from './ClassColumn/touch'
import CoursesColumn1 from './CoursesColumn-1/touch'
import CoursesColumn2 from './CoursesColumn-2/touch'
import CoursesDetailColumn from './CoursesDetailColumn/touch'
import RecordedGoodsColumn from './RecordedGoodsColumn/touch'
import CommonGoodsColumn from './CommonGoodsColumn/touch'
import NumberScroll from './NumberScroll/touch'
import EvaluationEntry from './EvaluationEntry/touch'
import Map from './Map/touch'
import Dynamic from './Dynamic/touch'
import ScoreQueryComponent from './ScoreQueryComponent/touch'
import MarketComponents from './MarketComponents/touch'

export default {
  name: 'DynamicComponent',
  components: {
    // 轮播图组件1
    Banner,
    // 轮播图组件2
    Banner2,
    // 轮播图组件3
    Banner3,
    // 分类组件
    Category,
    // 模拟考试栏目组件
    MockExamColumn,
    // 图文组件（一）
    ModuleRichText,
    // 图文组件（二）
    PageRichText,
    // 留资组件1
    GetNotified,
    // 留资组件2
    GetNotified2,
    // 注册组件
    Application,
    // 轮播组件
    CarouselComponent,
    // 吸底组件
    SuctionBottom,
    // 课程组件
    CourseListComponent,
    // 资讯组件
    NewsGroup,
    // 评价组件
    UserComments,
    // Ad-1
    Ad1,
    // Ad-2
    Ad2,
    // Ad-3
    Ad3,
    // Ad-4
    Ad4,
    // Ad-5
    Ad5,
    // Ad-6
    Ad6,
    // Ad-7
    Ad7,
    // Ad-8
    Ad8,
    // Ad-9
    Ad9,
    // 自定义表单组件
    CustomerForm,
    // 自定义按钮组件
    CustomButton,
    // 通用图片组件
    ImageList,
    // 通用图片组件2
    generalImgTwo,
    // 大图评价组件
    UserCommentsBigPicture,
    // Faqs 组件
    Faqs,
    // 富文本分栏组件
    RichTextColumn,
    // 资源组件
    resourcesColumn,
    // 班级栏目组件
    ClassColumn,
    // 课程栏目(一)组件
    CoursesColumn1,
    // 课程栏目(二)组件
    CoursesColumn2,
    // 课程栏目详情组件
    CoursesDetailColumn,
    // 录播课栏目组件
    RecordedGoodsColumn,
    // 通用商品组件
    CommonGoodsColumn,
    // 数字滚动组件
    NumberScroll,
    // 诊断入口组件
    EvaluationEntry,
    // 地图组件
    Map,
    // 动态组件
    Dynamic,
    // 评分组件
    Trustpilot,
    // 成绩查询组件
    ScoreQueryComponent,
    // 市场组件
    MarketComponents,
  },
  mixins: [mixin],
  beforeCreate() {
    if (process.server) return;
    window.onerror = function (message, url, line, column, error) {
      console.log('onerror 123', message, url, line, column, error);
    };
  },
  mounted() {
    if (process.server) return;
    const isApp = !!window.JsInjectionActivity || navigator.userAgent.indexOf('ThinkAcademy') !== -1;
    // 如果在端内打开禁止缩放
    if (isApp) {
      document.querySelector('#viewport').setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
    }
  },
}
</script>

<style lang="scss" scoped>
.component-wrapper {
  margin-bottom: 20px;

  &:last-child {
    margin-top: 0;
  }
}
</style>
